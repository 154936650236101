export const PARTNER: Record<string, string> = {
  GRAB: 'GRAB',
  FOODPANDA: 'FOODPANDA',
  GMB: 'GMB',
  FACEBOOK: 'FACEBOOK',
  DELIVEROO: 'DELIVEROO',
  GOOGLE: 'GOOGLE',
  DOORDASH: 'DOORDASH',
  YELP: 'YELP',
  UBER_EATS: 'UBER_EATS',
  GRUBHUB: 'GRUBHUB',
  ODDLE: 'ODDLE',
  OPEN_TABLE: 'OPEN_TABLE',
  SURVEY: 'SURVEY',
  REVEL: 'REVEL',
  EPOINT: 'EPOINT',
  TOAST: 'TOAST',
  OLO: 'OLO',
  PAYTRONIX: 'PAYTRONIX',
  TRIP_ADVISOR: 'TRIP_ADVISOR',
  INCENTIVIO: 'INCENTIVIO',
};

export const PARTNER_NAMES: Record<string, string> = {
  [PARTNER.GOOGLE]: 'google',
  [PARTNER.GRAB]: 'grab',
  [PARTNER.FOODPANDA]: 'foodpanda',
  [PARTNER.GMB]: 'gmb',
  [PARTNER.DELIVEROO]: 'deliveroo',
  [PARTNER.FACEBOOK]: 'facebook',
  [PARTNER.DOORDASH]: 'doordash',
  [PARTNER.UBER_EATS]: 'uber eats',
  [PARTNER.GRUBHUB]: 'grubhub',
};

export const SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS: string[] = [PARTNER.PAYTRONIX, PARTNER.OLO, PARTNER.REVEL];

export const SUPPORTED_ORDER_ITEMS_DETAILS_PLATFORMS_NAMES: Record<string, string> = {
  Paytronix: PARTNER.PAYTRONIX,
  Olo: PARTNER.OLO,
  Revel: PARTNER.REVEL,
  Incentivio: PARTNER.INCENTIVIO,
};
